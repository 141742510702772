<div class="h-full w-[480px] bg-white">
  <div class="flex h-20 items-center justify-between px-6">
    <h3 class="text-sidebar-headline font-medium">{{ getHeadline() }}</h3>
    <button class="emb-icon-button emb-button-clear-black" (click)="close()">
      <mat-icon svgIcon="Icon Close"></mat-icon>
    </button>
  </div>
  <div class="flex flex-col gap-6 px-6 py-6">
    @if (form && cardItem) {
      <form [formGroup]="form">
        <p class="text-gray-400-040">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
        <emad-button-group [options]="itemTypes" formControlName="type"></emad-button-group>

        <div class="flex flex-col gap-3 rounded-xl bg-gray-200 p-3" formGroupName="attribute">
          @if (cardItem.type === 'attribute') {
            <div class="emb-select-container">
              <label>Attribute</label>
              <select class="emb-select" formControlName="key">
                @for (type of properties; track $index) {
                  <option [value]="type.value">{{ type.label }}</option>
                }
              </select>
            </div>
            <div class="emb-select-container">
              <label>Render as</label>
              <select class="emb-select" formControlName="renderType">
                @for (type of renderTypes; track $index) {
                  <option [value]="type.value">{{ type.label }}</option>
                }
              </select>
            </div>
          } @else if (cardItem.type === 'element') {
            element form
          }

          <div class="flex items-center justify-between">
            <div>
              <button class="emb-button emb-button-clear-black">Cancel</button>
            </div>
            @if (type === 'edit') {
              <div class="flex gap-2">
                <button class="emb-button emb-button-red" (click)="delete()">Delete</button>
                <button class="emb-button emb-button-black" (click)="save()">Save</button>
              </div>
            } @else if (type === 'add') {
              <div>
                <button class="emb-button emb-button-black" (click)="save()">Save</button>
              </div>
            }
          </div>
        </div>
      </form>
    }
  </div>
</div>
