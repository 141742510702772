import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  public icons: string[] = [
    'Icon Add',
    'Icon Check',
    'Icon Chevron',
    'Icon Close',
    'Icon Cog',
    'Icon Data',
    'Icon dcupl',
    'Icon Design',
    'Icon Desktop',
    'Icon Filter',
    'Icon Image Folder',
    'Icon Layout',
    'Icon Lock',
    'Icon Login',
    'Icon Mobile',
    'Icon More',
    'Icon Pen',
    'Icon Settings',
    'Icon Share',
    'Icon Sort',
    'Icon Star',
    'Icon Sync',
    'Icon Tablet',
    'Icon Trash',
    'X Icon',
  ];

  public additionalSVGs: string[] = [
    'embert',
    'google',
    'facebook',
    'email',
    'card_1',
    'card_2',
    'card_3',
    'card_4',
    'table_row_1',
    'table_row_2',
    'color_scheme_1',
    'color_scheme_2',
    'color_scheme_3',
    'color_scheme_4',
    'color_scheme_5',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  public initRegistry(): void {
    this.icons.map((iconName: string) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${iconName}.svg`),
      );
    });

    this.additionalSVGs.map((iconName: string) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/svg/${iconName}.svg`),
      );
    });
  }
}
