import { ApplicationConfig, importProvidersFrom, InjectionToken } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
// import { getFirestore, provideFirestore } from '@angular/fire/firestore';
// import { getFunctions, provideFunctions } from '@angular/fire/functions';
// import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideHttpClient, withFetch } from '@angular/common/http';

import { SetPropertyFormComponent } from './shared/components/set-property-form/set-property-form.component';
import { FORM_COMPONENT, CONFIG_SERVICE } from '../../../../shared/directives/edit-button.directive';
import { ConfigService } from './services/config.service';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: FORM_COMPONENT, useValue: SetPropertyFormComponent },
    { provide: CONFIG_SERVICE, useExisting: ConfigService },
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    importProvidersFrom(
      provideFirebaseApp(() =>
        initializeApp({
          projectId: 'embert-io',
          appId: '1:494173859091:web:7b1127e77ff98c7feafee6',
          storageBucket: 'embert-io.appspot.com',
          apiKey: 'AIzaSyALuydWxu2HfoMwcO7uPB49htEfosp0lVA',
          authDomain: 'embert-io.firebaseapp.com',
          messagingSenderId: '494173859091',
          measurementId: 'G-VYDBHTM174',
        }),
      ),
    ),
    importProvidersFrom(provideAuth(() => getAuth())),
    importProvidersFrom(provideAnalytics(() => getAnalytics())),
    ScreenTrackingService,
    UserTrackingService,
    // importProvidersFrom(provideFirestore(() => getFirestore())),
    // importProvidersFrom(provideFunctions(() => getFunctions())),
    // importProvidersFrom(provideStorage(() => getStorage())),
  ],
};
