import { DialogRef } from '@angular/cdk/dialog';
import { Component, HostBinding } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { sideBarSlideInFromRight } from '../../animations/animations';
import { ButtonGroupComponent } from '../button-group/button-group.component';
import { SelectOption } from '../../../../types/form.types';
import { DcuplService } from '../../../services/dcupl.service';
import { ConfigService } from '../../../services/config.service';
import {
  LayoutConfigCardItem,
  LayoutConfigCardItemRenderType,
  LayoutConfigCardItemType,
} from '../../../../../../../shared/types/config/layout-config.types';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

export type PropertyFormReturnType = {
  type: 'set' | 'delete';
  item: LayoutConfigCardItem;
};

@Component({
  selector: 'emad-set-property-form',
  standalone: true,
  imports: [ReactiveFormsModule, MatIconModule, ButtonGroupComponent],
  templateUrl: './set-property-form.component.html',
  styleUrl: './set-property-form.component.scss',
  animations: [sideBarSlideInFromRight],
})
export class SetPropertyFormComponent {
  @HostBinding('@sideBarSlideInFromRight') animationState = '';

  public type: 'add' | 'edit' = 'add';

  public itemTypes: SelectOption<LayoutConfigCardItemType>[] = [
    { value: 'attribute', label: 'Attribute' },
    { value: 'element', label: 'Element' },
  ];

  public properties: SelectOption<string>[] = [];

  public renderTypes: SelectOption<LayoutConfigCardItemRenderType>[] = [
    { value: 'text', label: 'Text' },
    { value: 'strong', label: 'Strong' },
    { value: 'light', label: 'Light' },
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
  ];

  public form: FormGroup | undefined;

  public cardItem: LayoutConfigCardItem | undefined;

  constructor(
    private dialogRef: DialogRef,
    private dcuplService: DcuplService,
    private configService: ConfigService,
  ) {}

  public init(position: string, property?: LayoutConfigCardItem) {
    this.configService.config$.subscribe((config) => {
      this.properties = config.data.availableProperties.map((prop) => ({ value: prop.key, label: prop.key }));
    });

    if (!property) {
      this.cardItem = {
        position: position,
        type: 'attribute',
        attribute: {
          key: '',
          renderType: 'text',
          format: '',
          prefix: '',
          suffix: '',
        },
      };
      this.type = 'add';
    } else {
      this.cardItem = property;
      this.type = 'edit';
    }

    this.initForm(this.cardItem);

    this.form?.valueChanges.subscribe((value) => {
      this.cardItem = this.form?.value;
    });
  }

  private initForm(property?: LayoutConfigCardItem) {
    this.form = new FormGroup({
      position: new FormControl(property?.position),
      type: new FormControl(property?.type || 'attribute'),
      attribute: new FormGroup({
        key: new FormControl(property?.attribute?.key),
        renderType: new FormControl(property?.attribute?.renderType),
        format: new FormControl(property?.attribute?.format),
        prefix: new FormControl(property?.attribute?.prefix),
        suffix: new FormControl(property?.attribute?.suffix),
      }),
      element: new FormGroup({
        type: new FormControl(property?.element?.type),
        value: new FormControl(property?.element?.value),
      }),
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public getHeadline() {
    return this.type === 'add' ? 'Add Attribute' : 'Edit Attribute';
  }

  public delete() {
    const value: PropertyFormReturnType = {
      type: 'delete',
      item: this.form!.value,
    };

    this.dialogRef.close(value);
  }

  public save() {
    const value: PropertyFormReturnType = {
      type: 'set',
      item: this.form!.value,
    };

    this.dialogRef.close(value);
  }
}
