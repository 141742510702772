import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./application/pages/page-application/page-application.component').then((m) => m.PageApplicationComponent),
  },
  {
    path: 'design-system',
    loadComponent: () => import('./design-system/design-system.component').then((m) => m.DesignSystemComponent),
  },
  {
    path: 'legal',
    loadComponent: () => import('./common/pages/page-legal/page-legal.component').then((m) => m.PageLegalComponent),
  },
  {
    path: 'privacy',
    loadComponent: () =>
      import('./common/pages/page-privacy/page-privacy.component').then((m) => m.PagePrivacyComponent),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];
