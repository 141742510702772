import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IconService } from './services/icon.service';
import { ConfigService } from './services/config.service';
import { DbService } from './services/db.service';

@Component({
  selector: 'emad-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'embert-admin';
  public loading = true;
  constructor(
    private iconService: IconService,
    private configService: ConfigService,
    private dbService: DbService,
  ) {}

  ngOnInit(): void {
    this.init();
  }
  private async init() {
    this.iconService.initRegistry();
    this.configService.init();
    await this.dbService.init();
    this.loading = false;
  }
}
